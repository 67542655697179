
<template>
  <div>
    <b-table-simple bordered small>
      <b-thead>
        <b-tr>
          <b-th class="th-class"> NIVEL COGNITIVO </b-th>
          <b-th class="th-class">
            CANTIDAD DE
            <span
              v-b-tooltip.v-secondary.noninteractive="
                `${$getVisibleNames(
                  'manual.indicador_de_logro',
                  true,
                  'Indicadores de Logros'
                ).toUpperCase()}`
              "
            >
              (IL)
            </span>
          </b-th>
          <b-th class="th-class">
            <span>
              CANTIDAD DE
              {{
                $getVisibleNames(
                  "manual.dimension",
                  false,
                  "Dimensión"
                ).toUpperCase()
              }}
            </span>
            <button-add
              v-if="allows_crud && rubric && !rubric.is_locked"
              class="m-0 ml-2 p-0 text-white"
              v-b-tooltip.v-secondary.top.noninteractive="
                `Crear ${$getVisibleNames(
                  'manual.dimension',
                  false,
                  'Dimensión'
                )}`
              "
              @click="$bvModal.show(`modal-created-rubric-criteria-${test.id}`)"
              size="sm"
            >
            </button-add>
          </b-th>
          <b-th class="th-class">PODERACIÓN</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(cognitive_level, index) in cognitiveLevelList"
          :key="'NewCognitiveLevelDimensionsST:' + index + '-' + test.id"
        >
          <b-td
            class="text-center"
            :class="{ 'underline-cell': cognitive_level.id != null }"
            v-b-tooltip.v-secondary.top.noninteractive="
              `${
                cognitive_level.id != null
                  ? cognitive_level.level + '. ' + cognitive_level.name
                  : ''
              }`
            "
            >{{ cognitive_level.level }}</b-td
          >
          <b-td class="text-center">
            <template
              v-if="countUniqueIL(cognitive_level.rubric_criterias) > 0"
            >
              {{ countUniqueIL(cognitive_level.rubric_criterias) }}
            </template>
            <template v-else>-</template>
          </b-td>
          <td class="text-center">
            <div v-if="cognitive_level.rubric_criterias.length > 0">
              <div class="underline-cell">
                <span
                  :id="`cl-rubric-criteria-cell-${cognitive_level.id}-${test.id}`"
                >
                  {{ cognitive_level.rubric_criterias.length }}
                </span>
              </div>
              <template
                v-for="rubric_criteria in cognitive_level.rubric_criterias"
              >
                <b-modal
                  :key="`modal-update-rubric-instance-${test.id}-${rubric_criteria.id}-${cognitive_level.id}`"
                  :id="`modal-update-rubric-instance-${test.id}-${rubric_criteria.id}-${cognitive_level.id}`"
                  :title="
                    'Modificar ' +
                    $getVisibleNames('manual.dimension', false, 'Dimensión')
                  "
                  hide-footer
                  size="xl"
                >
                  <RubricCriteriaForm
                    :rubric_id="rubric.id"
                    :RubricCriteria="rubric_criteria"
                    :order="rubric_criteria.order"
                    :evaluation_criteria_micro_ids="
                      evaluation_criteria_micro_ids
                    "
                    :show_delete_button="true"
                    @updated="
                      $bvModal.hide(
                        `modal-update-rubric-instance-${test.id}-${rubric_criteria.id}-${cognitive_level.id}`
                      )
                    "
                    @deleted="
                      $bvModal.hide(
                        `modal-update-rubric-instance-${test.id}-${rubric_criteria.id}-${cognitive_level.id}`
                      )
                    "
                  ></RubricCriteriaForm>
                </b-modal>
              </template>
              <b-popover
                :target="`cl-rubric-criteria-cell-${cognitive_level.id}-${test.id}`"
                triggers="hover"
              >
                <template #title>
                  {{
                    $getVisibleNames(
                      "evaluations.observationachievement",
                      true,
                      "Niveles de Logro"
                    )
                  }}
                </template>
                <div
                  v-for="rubric_criteria in cognitive_level.rubric_criterias"
                  :key="
                    rubric_criteria.id +
                    '-' +
                    cognitive_level.id +
                    '-' +
                    test.id
                  "
                  class="d-flex"
                >
                  <div class="w-100">
                    <b-icon icon="circle-fill" scale="0.35"></b-icon>
                    {{
                      $getVisibleNames("manual.dimension", false, "Dimensión")
                    }}
                    N°
                    {{ rubric_criteria.order }}
                  </div>
                  <div
                    v-if="allows_crud && rubric && !rubric.is_locked"
                    class="d-flex"
                  >
                    <button-edit
                      class="m-0 p-0"
                      v-b-tooltip.top.noninteractive.v-secondary="
                        `Editar ${$getVisibleNames(
                          'manual.dimension',
                          false,
                          'Dimensión'
                        )}`
                      "
                      @click="
                        $bvModal.show(
                          `modal-update-rubric-instance-${test.id}-${rubric_criteria.id}-${cognitive_level.id}`
                        )
                      "
                    ></button-edit>
                    <!-- <b-button
                      class="m-0 p-0 text-danger"
                      v-b-tooltip.top.noninteractive.v-secondary="
                        `Eliminar la ${$getVisibleNames(
                          'manual.dimension',
                          false,
                          'Dimensión'
                        )}`
                      "
                      variant="none"
                      @click="deleteRubricCriteria(rubric_criteria.id)"
                    >
                      <b-icon-trash
                        class="icon-trash"
                        font-scale="1"
                      ></b-icon-trash>
                    </b-button> -->
                  </div>
                </div>
              </b-popover>
            </div>
            <template v-else>-</template>
          </td>
          <td class="text-center">
            {{ totalCountWeighingCL(cognitive_level.rubric_criterias) }}
          </td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr class="cl-tfoot">
          <b-td class="text-center">TOTAL</b-td>
          <b-td class="text-center">
            {{ countUniqueIL(rubricCriteriaList) }}
          </b-td>
          <b-td class="text-center">{{ rubricCriteriaList.length }}</b-td>
          <b-td class="text-center">
            {{
              rubricCriteriaList.reduce(
                (acc, x) => acc + parseFloat(x.weighing),
                0
              )
            }}%
          </b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
    <!-- b-modal -->
    <b-modal
      :id="`modal-created-rubric-criteria-${test.id}`"
      :title="`Crear ${$getVisibleNames(
        'manual.dimension',
        false,
        'Dimensión'
      )}`"
      size="xl"
      hide-footer
    >
      <RubricCriteriaForm
        v-if="rubric"
        :rubric_id="rubric.id"
        :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
        :order="
          rubricCriteriaList.length > 0
            ? rubricCriteriaList[rubricCriteriaList.length - 1].order + 1
            : 1
        "
        @created="$bvModal.hide(`modal-created-rubric-criteria-${test.id}`)"
      ></RubricCriteriaForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "NewCognitiveLevelDimensionsST",
  components: {
    RubricCriteriaForm: () =>
      import("@/components/new-evaluations/Rubrics/RubricCriteriaForm"),
  },
  props: {
    allows_crud: {
      type: Boolean,
      default: false,
    },
    test: {
      type: Object,
      required: true,
    },
    rubric_id: {
      type: Number,
      required: true,
    },
    evaluation_criteria_micro_ids: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      taxonomy: names.TAXONOMY,
      rubrics: names.NEW_RUBRIC_INSTRUMENTS,
      rubricCriterias: names.NEW_RUBRIC_CRITERIAS,
      evaluation_criteria_micros: names.EVALUATION_CRITERIAS,
    }),
    rubric() {
      return this.rubrics.find((x) => x.id == this.rubric_id);
    },
    rubricCriteriaList() {
      if (!this.rubric) return [];
      return this.rubricCriterias
        .filter((x) => x.rubric == this.rubric.id)
        .map((x) => ({
          ...x,
          cognitive_level: this.getCognitiveLevelId(
            (
              this.evaluation_criteria_micros.find(
                (s) => s.id == x.evaluation_criteria_micro
              ) || {}
            ).verb || null
          ),
        }))
        .sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
    },
    cognitiveLevelList() {
      let cognitive_levels = [{ id: null, level: "N/A", name: "N/A" }];
      cognitive_levels = cognitive_levels.concat(
        this.taxonomy.cognitive_levels
      );
      return cognitive_levels.map((x) => {
        return {
          ...x,
          rubric_criterias: this.rubricCriteriaList.filter(
            (q) => q.cognitive_level == x.id
          ),
        };
      });
    },
  },
  methods: {
    getCognitiveLevel(verb_id) {
      if (verb_id == null) return verb_id;
      return this.taxonomy.cognitive_levels.find((cognitive_level) =>
        cognitive_level.verbs.some((verb) => verb.id == verb_id)
      );
    },
    getCognitiveLevelId(verb_id) {
      if (verb_id == null) return verb_id;
      const cognitive_level = this.getCognitiveLevel(verb_id);
      return cognitive_level ? cognitive_level.id : null;
    },
    countUniqueIL(rubric_criterias) {
      return [
        ...new Set(rubric_criterias.map((x) => x.evaluation_criteria_micro)),
      ].length;
    },
    totalCountWeighingCL(rubric_criterias) {
      if (rubric_criterias.length == 0) return "-";
      return (
        rubric_criterias.reduce((acc, x) => acc + parseFloat(x.weighing), 0) +
        "%"
      );
    },
    // deleteRubricCriteria(rubric_criteria_id) {
    //   this.$swal({
    //     title: `¿Está seguro de que desea eliminar la ${this.$getVisibleNames(
    //       "manual.dimension",
    //       false,
    //       "Dimensión"
    //     )}?`,
    //     text: "¡Esta acción no se podrá revertir!",
    //     type: "warning",
    //     showCancelButton: true,
    //   }).then((result) => {
    //     if (result.value) {
    //       this.$store.dispatch(
    //         names.DELETE_NEW_RUBRIC_CRITERIA,
    //         rubric_criteria_id
    //       );
    //     }
    //   });
    // },
  },
  created() {},
};
</script>

<style scoped>
.th-class {
  background-color: var(--kl-menu-color) !important;
  color: var(--secondary-font-color);
  font-size: var(--secondary-font-size);
  text-align: center;
}
.underline-cell {
  text-decoration: underline;
  cursor: help;
}
.cl-tfoot {
  background-color: #eee;
}
</style>

